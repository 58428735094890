import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Container from "../../Container/Container"

// import { HiOutlineDocumentAdd } from "react-icons/hi"
import { MdOutlineUpdate } from "react-icons/md"

import "./Post.css"

const Post = ({ frontmatter }) => {
  const { title, image, slug, date, category } = frontmatter

  let isShortTitle = false
  let shortTitle
  if (title.length >= 40) {
    shortTitle = title.substr(0, 38)
    isShortTitle = true
  } else {
    shortTitle = title
  }

  return (
    <Container>
      <div className="post-wrapper">
        <GatsbyImage image={getImage(image)} alt={title} className="post-img" />
        <div className="post-info">
          <div className="post-title-wrapper">
            <h3 className="post-title">
              {isShortTitle ? `${shortTitle}...` : shortTitle}
            </h3>
          </div>
          <div className="post-sub-info">
            <div className="post-category">
              {category.split(",").map((tag, index) => {
                return (
                  <div key={index} className="post-category-text">
                    {tag}
                  </div>
                )
              })}
            </div>
            <div className="post-date-wrapper">
              {/* <div className="post-date">
                <MdDateRange className="post-date-icon" />
                {date}
              </div> */}
              <div className="post-date">
                <MdOutlineUpdate className="post-date-icon" />
                {date}
              </div>
            </div>
          </div>
        </div>
        <Link to={`/posts/${slug}`} className="post-link"></Link>
      </div>
    </Container>
  )
}

export default Post
