import React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import Post from "../components/Posts/Post/Post"
import Container from "../components/Container/Container"
import Banner from "../components/Banner/Banner"

import "../assets/css/blog-template.css"

const PostsPage = ({ data, pageContext }) => {
  const {
    allMdx: { nodes: posts },
  } = data
  const { currentPage, isFirstPage, isLastPage, totalPages } = pageContext
  const nextPage = `/blog/${String(currentPage + 1)}`
  const prevPage =
    currentPage - 1 === 1 ? "/blog" : `/blog/${String(currentPage - 1)}`

  return (
    <Layout>
      <Container>
        <section className="blog-wrapper">
          <p className="main-title">
            全ての記事{" "}
            <span className="blog-sub-title">
              - {currentPage}/{totalPages}
            </span>
          </p>
          <div className="blog-wrapper">
            {/* posts column */}
            <article>
              {posts.map(post => {
                return <Post key={post.id} {...post} />
              })}
            </article>

            {/* banner column */}
            <article>
              <Banner />
            </article>
          </div>
        </section>

        {/* pagenation links */}
        <div className="pagenation-links">
          {!isFirstPage ? (
            <Link to={prevPage} className="pagenation-link">
              前へ
            </Link>
          ) : (
            <div className="pagenation-noLink">前へ</div>
          )}
          {Array.from({ length: totalPages }, (_, index) => (
            <Link
              key={index}
              to={`/blog/${index === 0 ? "" : index + 1}`}
              className={
                index + 1 === currentPage
                  ? "pagenation-link pagenation-active"
                  : "pagenation-link"
              }
            >
              {index + 1}
            </Link>
          ))}
          {!isLastPage ? (
            <Link to={nextPage} className="pagenation-link">
              次へ
            </Link>
          ) : (
            <div className="pagenation-noLink">次へ</div>
          )}
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMdx(
      skip: $skip
      limit: $limit
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        excerpt
        frontmatter {
          title
          author
          category
          readTime
          date(formatString: "YYYY-MM-DD")
          slug
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
      }
    }
  }
`

export default PostsPage
